<script>
import Layout from "../../layouts/main";
import { dashboardService } from "../../../helpers/dash.service";
import { posService } from "../../../helpers/cattel.service";
import Swal from "sweetalert2";

import {
  livestockAllChart,
  CountCowToday,
  milkdryinoc,
  milkdrybirth,
  milkdrymilk,
  milkdrybcs,
  milkdrymove,
  pregnant,
  pregnant_time,
  nonpregnant,
  nonpregnant_inoc,
  clafCows,
} from "./chart";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      livestockAllChart: livestockAllChart,
      CountCowToday: CountCowToday,
      milkchartOptionsinoc: milkdryinoc.chartOptions,
      milkchartOptionsbirth: milkdrybirth.chartOptions,
      milkchartOptionsmilk: milkdrymilk.chartOptions,
      milkchartOptionsbcs: milkdrybcs.chartOptions,
      milkchartOptionsmove: milkdrymove.chartOptions,
      milkpostionchartOptionsinoc: milkdryinoc.chartOptions,
      milkpostionchartOptionsbirth: milkdrybirth.chartOptions,
      milkpostionchartOptionsmilk: milkdrymilk.chartOptions,
      milkpostionchartOptionsbcs: milkdrybcs.chartOptions,
      milkpostionchartOptionsmove: milkdrymove.chartOptions,
      drychartOptionsinoc: milkdryinoc.chartOptions,
      drychartOptionsbirth: milkdrybirth.chartOptions,
      drychartOptionsmilk: milkdrymilk.chartOptions,
      drychartOptionsbcs: milkdrybcs.chartOptions,
      drychartOptionsmove: milkdrymove.chartOptions,
      drypostionchartOptionsinoc: milkdryinoc.chartOptions,
      drypostionchartOptionsbirth: milkdrybirth.chartOptions,
      drypostionchartOptionsmilk: milkdrymilk.chartOptions,
      drypostionchartOptionsbcs: milkdrybcs.chartOptions,
      drypostionchartOptionsmove: milkdrymove.chartOptions,
      milkinoc: [],
      milkbirthnum: [],
      milkrecord: [],
      milkbsc: [],
      milkmove: [],
      milkpostioninoc: [],
      milkpostionbirthnum: [],
      milkpostionrecord: [],
      milkpostionbsc: [],
      milkpostionmove: [],
      dryinoc: [],
      drybirthnum: [],
      dryrecord: [],
      drybsc: [],
      drymove: [],
      drypostioninoc: [],
      drypostionbirthnum: [],
      drypostionrecord: [],
      drypostionbsc: [],
      drypostionmove: [],
      pregnant: pregnant,
      pregnant_time: pregnant_time,
      nonpregnant: nonpregnant,
      nonpregnant_inoc: nonpregnant_inoc,
      clafCows: clafCows,
      milkselected: [],
      milkposselected: [],
      dryselected: [],
      dryposselected: [],
      MilkMinDIM: 0,
      MilkMaxDIM: 0,
      MilkPostionMinDIM: 0,
      MilkPostionMaxDIM: 0,
      DryMinDIM: 0,
      DryMaxDIM: 0,
      DryPostionMinDIM: 0,
      DryPostionMaxDIM: 0,
      livestockAllFullCount: "",
      MilkFullCount: "",
      DryFullCount: "",
      PregnantFullCount: "",
      nonPregnantFullCount: "",
      clafCowsFullcount: "",
      milkdryoptions: [
        { text: "همه", value: "all" },
        { text: "آبستن", value: "pregnancy" },
        { text: "شیرواری ۱", value: "birthones" },
        { text: "تلقیح شده", value: "inoculated" },
        { text: "بدون تلقیح", value: "uninoculated" },
      ],
      milkdryposoptions: [],
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
    };
  },
  beforeMount() {
    dashboardService.AllChart().then((data) => {
      this.livestockAllChart.series = data.message;
    });
    dashboardService.AllChart2().then((data) => {
      this.CountCowToday.series = data.message;
      this.livestockAllFullCount = data.count;

    });
    posService.getPositions().then((data) => {
      this.milkdryposoptions = [{ text: "همه", value: "all" }];
      data.message.forEach((element) => {
        this.milkdryposoptions.push({
          text: element.position,
          value: element.id,
        });
      });
    });
  },
  methods: {
    toast(toaster, variant, title, contant) {
      this.counter++;
      this.$bvToast.toast(contant, {
        title: title,
        toaster: toaster,
        variant: variant,
        solid: true,
      });
    },
    CheckPeriod(target) {
      if (
        (this.MilkMinDIM != "" &&
          this.MilkMaxDIM != "" &&
          this.MilkMinDIM != "0" &&
          this.MilkMaxDIM != "0") ||
        (this.MilkPostionMinDIM != "" &&
          this.MilkPostionMaxDIM != "" &&
          this.MilkPostionMinDIM != "0" &&
          this.MilkPostionMaxDIM != "0") ||
        (this.DryMinDIM != "" &&
          this.DryMaxDIM != "" &&
          this.DryMinDIM != "0" &&
          this.DryMaxDIM != "0") ||
        (this.DryPostionMinDIM != "" &&
          this.DryPostionMaxDIM != "" &&
          this.DryPostionMinDIM != "0" &&
          this.DryPostionMaxDIM != "0")
      ) {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "flex";
        switch (target) {
          case "Milk":
            {
              let showData = this.milkselected;
              let ListOfCow = showData.join(",");
              if (ListOfCow == "") {
                ListOfCow = "all";
              }
              dashboardService
                .Milk(ListOfCow, this.MilkMinDIM, this.MilkMaxDIM)
                .then((data) => {
                  document.getElementById("preloader").style.display = "none";
                  if (data.success) {
                    this.milkinoc = data.message.inoc;
                    this.milkbirthnum = data.message.birthnum;
                    this.milkrecord = data.message.record;
                    this.milkbsc = data.message.bsc;
                    this.milkmove = data.message.move;
                  } else {
                    this.swalWithBootstrapButtons.fire({
                      text: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    });
                  }
                });
            }
            break;
          case "MilkPostion":
            {
              let showData = this.milkposselected;
              let ListOfCow = showData.join(",");
              if (ListOfCow == "") {
                ListOfCow = "all";
              }
              dashboardService
                .MilkPostion(
                  ListOfCow,
                  this.MilkPostionMinDIM,
                  this.MilkPostionMaxDIM
                )
                .then((data) => {
                  document.getElementById("preloader").style.display = "none";
                  if (data.success) {
                    this.milkpostioninoc = data.message.inoc;
                    this.milkpostionbirthnum = data.message.birthnum;
                    this.milkpostionrecord = data.message.record;
                    this.milkpostionbsc = data.message.bsc;
                    this.milkpostionmove = data.message.move;
                  } else {
                    this.swalWithBootstrapButtons.fire({
                      text: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    });
                  }
                });
            }
            break;
          case "Dry":
            {
              let showData = this.dryselected;
              let ListOfCow = showData.join(",");
              if (ListOfCow == "") {
                ListOfCow = "all";
              }
              dashboardService
                .Dry(ListOfCow, this.DryMinDIM, this.DryMaxDIM)
                .then((data) => {
                  document.getElementById("preloader").style.display = "none";
                  if (data.success) {
                    this.dryinoc = data.message.inoc;
                    this.drybirthnum = data.message.birthnum;
                    this.dryrecord = data.message.record;
                    this.drybsc = data.message.bsc;
                    this.drymove = data.message.move;
                  } else {
                    this.swalWithBootstrapButtons.fire({
                      text: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    });
                  }
                });
            }
            break;
          case "DryPostion":
            {
              let showData = this.dryposselected;
              let ListOfCow = showData.join(",");
              if (ListOfCow == "") {
                ListOfCow = "all";
              }
              dashboardService
                .DryPostion(
                  ListOfCow,
                  this.DryPostionMinDIM,
                  this.DryPostionMaxDIM
                )
                .then((data) => {
                  document.getElementById("preloader").style.display = "none";
                  if (data.success) {
                    this.drypostioninoc = data.message.inoc;
                    this.drypostionbirthnum = data.message.birthnum;
                    this.drypostionrecord = data.message.record;
                    this.drypostionbsc = data.message.bsc;
                    this.drypostionmove = data.message.move;
                  } else {
                    this.swalWithBootstrapButtons.fire({
                      text: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    });
                  }
                });
            }
            break;
        }
      } else {
        this.LoadMoreChart(target);
      }
    },
    LoadMoreChart(target) {
      switch (target) {
        case "Milk":
          {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "flex";
            let showData = this.milkselected;
            let ListOfCow = showData.join(",");
            if (ListOfCow == "") {
              ListOfCow = "all";
            }
            dashboardService.Milk(ListOfCow).then((data) => {
              this.milkinoc = data.message.inoc;
              this.milkbirthnum = data.message.birthnum;
              this.milkrecord = data.message.record;
              this.milkbsc = data.message.bsc;
              this.milkmove = data.message.move;
              this.MilkFullCount = data.count;
              document.getElementById("preloader").style.display = "none";
            });
          }
          break;
        case "MilkPostion":
          {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "flex";
            let showData = this.milkposselected;
            let ListOfCow = showData.join(",");
            if (ListOfCow == "") {
              ListOfCow = "all";
            }
            dashboardService.MilkPostion(ListOfCow).then((data) => {
              this.milkpostioninoc = data.message.inoc;
              this.milkpostionbirthnum = data.message.birthnum;
              this.milkpostionrecord = data.message.record;
              this.milkpostionbsc = data.message.bsc;
              this.milkpostionmove = data.message.move;
              document.getElementById("preloader").style.display = "none";
            });
          }
          break;
        case "Dry":
          {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "flex";
            let showData = this.dryselected;
            let ListOfCow = showData.join(",");
            if (ListOfCow == "") {
              ListOfCow = "all";
            }
            dashboardService.Dry(ListOfCow).then((data) => {
              this.dryinoc = data.message.inoc;
              this.drybirthnum = data.message.birthnum;
              this.dryrecord = data.message.record;
              this.drybsc = data.message.bsc;
              this.drymove = data.message.move;
              this.DryFullCount = data.count;
              document.getElementById("preloader").style.display = "none";
            });
          }
          break;
        case "DryPostion":
          {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "flex";
            let showData = this.dryposselected;
            let ListOfCow = showData.join(",");
            if (ListOfCow == "") {
              ListOfCow = "all";
            }
            dashboardService.DryPostion(ListOfCow).then((data) => {
              this.drypostioninoc = data.message.inoc;
              this.drypostionbirthnum = data.message.birthnum;
              this.drypostionrecord = data.message.record;
              this.drypostionbsc = data.message.bsc;
              this.drypostionmove = data.message.move;
              document.getElementById("preloader").style.display = "none";
            });
          }
          break;
        case "Pregnant":
          dashboardService.Pregnant().then((data) => {
            this.pregnant.chartOptions.xaxis.categories =
              data.message.categories;
            this.pregnant.series = data.message;
            this.PregnantFullCount = data.message.length;
          });
          dashboardService.PregnantTime().then((data) => {
            this.pregnant_time.series = data.message;
          });
          break;
        case "nonPregnant":
          dashboardService.nonPregnant().then((data) => {
            this.nonpregnant.series = data.message;
            this.nonPregnantFullCount = data.count;
          });
          dashboardService.nonPregnantInoc().then((data) => {
            this.nonpregnant_inoc.chartOptions.xaxis.categories =
              data.message.categories;
            this.nonpregnant_inoc.series = data.message;
          });
          break;
        case "CalfChart":
          dashboardService.CalfChart().then((data) => {
            this.clafCows.series = data.message;
            this.clafCowsFullcount = data.count;
          });
          break;
      }
    },
    ChangeOption(target) {
      if (target == "milk") {
        if (this.milkselected.length) {
          if (this.milkselected.length > 1 && this.milkselected[0] == "all") {
            this.milkselected = this.milkselected.filter(function (item) {
              return item !== "all";
            });
          }
          if (this.milkselected.includes("all")) {
            this.milkselected = ["all"];
          }
          let showData = this.milkselected;
          let ListOfCow = showData.join(",");
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "flex";
          dashboardService
            .Milk(ListOfCow, this.MilkMinDIM, this.MilkMaxDIM)
            .then((data) => {
              this.milkinoc = data.message.inoc;
              this.milkbirthnum = data.message.birthnum;
              this.milkrecord = data.message.record;
              this.milkbsc = data.message.bsc;
              this.milkmove = data.message.move;
              document.getElementById("preloader").style.display = "none";
            });
        }
      } else {
        if (this.dryselected.length) {
          if (this.dryselected.length > 1 && this.dryselected[0] == "all") {
            this.dryselected = this.dryselected.filter(function (item) {
              return item !== "all";
            });
          }
          if (this.dryselected.includes("all")) {
            this.dryselected = ["all"];
          }
          let showData = this.dryselected;
          let ListOfCow = showData.join(",");
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "flex";
          dashboardService
            .Dry(ListOfCow, this.DryMinDIM, this.DryMaxDIM)
            .then((data) => {
              this.dryinoc = data.message.inoc;
              this.drybirthnum = data.message.birthnum;
              this.dryrecord = data.message.record;
              this.drybsc = data.message.bsc;
              this.drymove = data.message.move;
              document.getElementById("preloader").style.display = "none";
            });
        }
      }
    },
    PosChangeOption(target) {
      if (target == "milk") {
        if (this.milkposselected.length) {
          if (
            this.milkposselected.length > 1 &&
            this.milkposselected[0] == "all"
          ) {
            this.milkposselected = this.milkposselected.filter(function (item) {
              return item !== "all";
            });
          }
          if (this.milkposselected.includes("all")) {
            this.milkposselected = ["all"];
          }
          let showData = this.milkposselected;
          let ListOfCow = showData.join(",");
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "flex";
          dashboardService
            .MilkPostion(
              ListOfCow,
              this.MilkPostionMinDIM,
              this.MilkPostionMaxDIM
            )
            .then((data) => {
              this.milkpostioninoc = data.message.inoc;
              this.milkpostionbirthnum = data.message.birthnum;
              this.milkpostionrecord = data.message.record;
              this.milkpostionbsc = data.message.bsc;
              this.milkpostionmove = data.message.move;
              document.getElementById("preloader").style.display = "none";
            });
        }
      } else {
        if (this.dryposselected.length) {
          if (
            this.dryposselected.length > 1 &&
            this.dryposselected[0] == "all"
          ) {
            this.dryposselected = this.dryposselected.filter(function (item) {
              return item !== "all";
            });
          }
          if (this.dryposselected.includes("all")) {
            this.dryposselected = ["all"];
          }
          let showData = this.dryposselected;
          let ListOfCow = showData.join(",");
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "flex";
          dashboardService
            .DryPostion(ListOfCow, this.DryPostionMinDIM, this.DryPostionMaxDIM)
            .then((data) => {
              this.drypostioninoc = data.message.inoc;
              this.drypostionbirthnum = data.message.birthnum;
              this.drypostionrecord = data.message.record;
              this.drypostionbsc = data.message.bsc;
              this.drypostionmove = data.message.move;
              document.getElementById("preloader").style.display = "none";
            });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs justified pills card content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-sm-inline-block">نمودار کل</span>
                  <span class="badge p-2 pl-5 font-size-14">{{
                    livestockAllFullCount
                  }}</span>
                </template>
                <b-row>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">نمودار تغییرات دام ها</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="line"
                          dir="ltr"
                          id="livestockAllChart"
                          :series="livestockAllChart.series"
                          :options="livestockAllChart.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">
                          نمودار تعداد دام - امروز
                        </h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="bar"
                          dir="ltr"
                          :series="CountCowToday.series"
                          :options="CountCowToday.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </b-row>
              </b-tab>
              <b-tab @click="LoadMoreChart('Milk')">
                <template v-slot:title>
                  <span class="d-sm-inline-block">نمودار شیری</span>
                  <span class="badge p-2 pl-5 font-size-14">{{
                    MilkFullCount
                  }}</span>
                </template>
                <b-card no-body>
                  <b-tabs pills justified card>
                    <b-tab class="col-sm-12" title="نمودار شیری" active>
                      <b-row>
                        <b-col md="8">
                          <b-form-group label=" " v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              v-model="milkselected"
                              :options="milkdryoptions"
                              :aria-describedby="ariaDescribedby"
                              switches
                              @change="ChangeOption('milk')"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form inline>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="MilkMaxDIM"
                              placeholder="حداکثر DIM"
                              :value="MilkMaxDIM"
                              :max="parseInt(MilkMinDIM) + 200"
                              v-model="MilkMaxDIM"
                            ></b-form-input>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="MilkMinDIM"
                              placeholder="حداقل DIM"
                              :value="MilkMinDIM"
                              min="0"
                              v-model="MilkMinDIM"
                            ></b-form-input>
                            <b-btn
                              class="mr-1 ml-1 btn-warning"
                              @click="CheckPeriod('Milk')"
                              >تایید</b-btn
                            >
                            <span>انتخاب دامنه DIM</span>
                          </b-form>
                        </b-col>
                      </b-row>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار روز تلقیح - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkinoc"
                          :options="milkchartOptionsinoc"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار شیرواری - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkbirthnum"
                          :options="milkchartOptionsbirth"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار رکورد شیر - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkrecord"
                          :options="milkchartOptionsmilk"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار DIM - BCS</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkbsc"
                          :options="milkchartOptionsbcs"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار حرکتی - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkmove"
                          :options="milkchartOptionsmove"
                        ></apexchart>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      @click="LoadMoreChart('MilkPostion')"
                      class="col-sm-12"
                      title="نمودار جایگاه"
                    >
                      <b-row>
                        <b-col md="8">
                          <b-form-group label=" " v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              v-model="milkposselected"
                              :options="milkdryposoptions"
                              :aria-describedby="ariaDescribedby"
                              switches
                              @change="PosChangeOption('milk')"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form inline>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="MilkPostionMaxDIM"
                              placeholder="حداکثر DIM"
                              :value="MilkPostionMaxDIM"
                              :max="parseInt(MilkPostionMinDIM) + 200"
                              v-model="MilkPostionMaxDIM"
                            ></b-form-input>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="MilkPostionMinDIM"
                              placeholder="حداقل DIM"
                              :value="MilkPostionMinDIM"
                              min="0"
                              v-model="MilkPostionMinDIM"
                            ></b-form-input>
                            <b-btn
                              class="mr-1 ml-1 btn-warning"
                              @click="CheckPeriod('MilkPostion')"
                              >تایید</b-btn
                            >
                            <span>انتخاب دامنه DIM</span>
                          </b-form>
                        </b-col>
                      </b-row>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار روز تلقیح - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkpostioninoc"
                          :options="milkpostionchartOptionsinoc"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار شیرواری - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkpostionbirthnum"
                          :options="milkpostionchartOptionsbirth"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار رکورد شیر - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkpostionrecord"
                          :options="milkpostionchartOptionsmilk"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار BCS - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkpostionbsc"
                          :options="milkpostionchartOptionsbcs"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار حرکتی - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="milkpostionmove"
                          :options="milkpostionchartOptionsmove"
                        ></apexchart>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab @click="LoadMoreChart('Dry')">
                <template v-slot:title>
                  <span class="d-sm-inline-block">نمودار خشک</span>
                  <span class="badge p-2 pl-5 font-size-14">{{
                    DryFullCount
                  }}</span>
                </template>
                <b-card no-body>
                  <b-tabs pills justified card>
                    <b-tab class="col-sm-12" title="نمودار خشک" active>
                      <b-row>
                        <b-col md="8">
                          <b-form-group label=" " v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              v-model="dryselected"
                              :options="milkdryoptions"
                              :aria-describedby="ariaDescribedby"
                              switches
                              @change="ChangeOption('dry')"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form inline>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="DryMaxDIM"
                              placeholder="حداکثر DIM"
                              :value="DryMaxDIM"
                              :max="parseInt(DryMinDIM) + 200"
                              v-model="DryMaxDIM"
                            ></b-form-input>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="DryMinDIM"
                              placeholder="حداقل DIM"
                              :value="DryMinDIM"
                              min="0"
                              v-model="DryMinDIM"
                            ></b-form-input>
                            <b-btn
                              class="mr-1 ml-1 btn-warning"
                              @click="CheckPeriod('Dry')"
                              >تایید</b-btn
                            >
                            <span>انتخاب دامنه DIM</span>
                          </b-form>
                        </b-col>
                      </b-row>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار روز تلقیح - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="dryinoc"
                          :options="drychartOptionsinoc"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار شیرواری - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drybirthnum"
                          :options="drychartOptionsbirth"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار رکورد شیر - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="dryrecord"
                          :options="drychartOptionsmilk"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار BCS - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drybsc"
                          :options="drychartOptionsbcs"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار حرکتی - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drymove"
                          :options="drychartOptionsmove"
                        ></apexchart>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      @click="LoadMoreChart('DryPostion')"
                      class="col-sm-12"
                      title="نمودار جایگاه"
                    >
                      <b-row>
                        <b-col md="8">
                          <b-form-group label=" " v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              v-model="dryposselected"
                              :options="milkdryposoptions"
                              :aria-describedby="ariaDescribedby"
                              switches
                              @change="PosChangeOption('dry')"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form inline>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="DryPostionMaxDIM"
                              placeholder="حداکثر DIM"
                              :value="DryPostionMaxDIM"
                              :max="parseInt(DryPostionMinDIM) + 200"
                              v-model="DryPostionMaxDIM"
                            ></b-form-input>
                            <b-form-input
                              dir="ltr"
                              type="number"
                              class="col-2 mr-1 ml-1"
                              id="DryPostionMinDIM"
                              placeholder="حداقل DIM"
                              :value="DryPostionMinDIM"
                              min="0"
                              v-model="DryPostionMinDIM"
                            ></b-form-input>
                            <b-btn
                              class="mr-1 ml-1 btn-warning"
                              @click="CheckPeriod('DryPostion')"
                              >تایید</b-btn
                            >
                            <span>انتخاب دامنه DIM</span>
                          </b-form>
                        </b-col>
                      </b-row>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار روز تلقیح - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drypostioninoc"
                          :options="drypostionchartOptionsinoc"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار شیرواری - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drypostionbirthnum"
                          :options="drypostionchartOptionsbirth"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار رکورد شیر - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drypostionrecord"
                          :options="drypostionchartOptionsmilk"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار BCS - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drypostionbsc"
                          :options="drypostionchartOptionsbcs"
                        ></apexchart>
                      </b-card-text>
                      <b-card-text>
                        <h4 class="card-title mb-4">نمودار حرکتی - DIM</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="drypostionmove"
                          :options="drypostionchartOptionsmove"
                        ></apexchart>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab @click="LoadMoreChart('Pregnant')">
                <template v-slot:title>
                  <span class="d-sm-inline-block">نمودار تلیسه آبستن</span>
                  <span class="badge p-2 pl-5 font-size-14">{{
                    PregnantFullCount
                  }}</span>
                </template>
                <b-row>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">
                          نمودار مدت آبستنی - دام ها
                        </h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="bar"
                          dir="ltr"
                          :series="pregnant_time.series"
                          :options="pregnant_time.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">نمودار تلقیح - سن (روز)</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="pregnant.series"
                          :options="pregnant.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </b-row>
              </b-tab>
              <b-tab @click="LoadMoreChart('nonPregnant')">
                <template v-slot:title>
                  <span class="d-sm-inline-block">نمودار تلیسه غیر آبستن</span>
                  <span class="badge p-2 pl-5 font-size-14">{{
                    nonPregnantFullCount
                  }}</span>
                </template>
                <b-row>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">نمودار تعداد - سن</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="bar"
                          dir="ltr"
                          :series="nonpregnant.series"
                          :options="nonpregnant.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">نمودار تلقیح - سن (روز)</h4>
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="scatter"
                          dir="ltr"
                          :series="nonpregnant_inoc.series"
                          :options="nonpregnant_inoc.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </b-row>
              </b-tab>
              <b-tab @click="LoadMoreChart('CalfChart')">
                <template v-slot:title>
                  <span class="d-sm-inline-block">نمودار گوساله زیر یکسال</span>
                  <span class="badge p-2 pl-5 font-size-14">{{
                    clafCowsFullcount
                  }}</span>
                </template>
                <div class="row">
                  <div class="col-lg">
                    <div class="card">
                      <div class="card-body">
                        <apexchart
                          class="apex-charts"
                          height="600"
                          type="bar"
                          dir="ltr"
                          :series="clafCows.series"
                          :options="clafCows.chartOptions"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>