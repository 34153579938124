const livestockAllChart = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
            width: 3,
            // dashArray: 5
        },
        colors: ["#1cbb8c", "#ff3d60", "#4aa3ff", "#fcb92c", "#848484", "#3fff5f"],
        xaxis: {
            categories: ['30', '20', '10', 'امروز'],
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                }
            },
            title: {
                text: 'روز پیش',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                }
            },
            title: {
                text: 'تعداد دام',
                style: {
                    fontSize: '18px',
                }
            }
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    },
};
const CountCowToday = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "45%",
            },
        },
        stroke: {
            show: true,
            width: 10,
            colors: ["transparent"],
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#1cbb8c", "#ff3d60", "#4aa3ff", "#fcb92c", "#848484"],
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: ["امروز"],
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                }
            },
            title: {
                text: 'نوع دام',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            min:0,
            tickAmount:10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
            },
            title: {
                text: 'تعداد',
                style: {
                    fontSize: '18px',
                }
            }
        }
    },
};

const milkdryinoc = {
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },

        colors: ["#1cbb8c", "#fcb92c", "#4aa3ff", "#ff3d60", "#343a40", "#fcb92c", "#74788d", "#e83e8c", "#f1734f", "#5438DC", "#564ab1", "#5664d2", "#e83e8c", "#ff3d60", "#f1734f", "#fcb92c", "#1cbb8c", "#050505", "#4aa3ff", "#ffffff", "#74788d", "#343a40", "#5664d2", "#74788d", "#1cbb8c", "#4aa3ff", "#fcb92c", "#ff3d60", "#eff2f7", "#343a40", "#e83e8c"],
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            },
            title: {
                text: 'DIM',
                style: {
                    fontSize: '18px',
                }
            }
        },
        legend: {
            show: false,
        },
        yaxis: {
            min: 0,
            max: 300,
            tickAmount: 11,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            }
        },
        title: {
            text: 'تلقیح',
            style: {
                fontSize: '18px',
            }
        },
    },
};

const milkdrybirth = {
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            },
            title: {
                text: 'DIM',
                style: {
                    fontSize: '18px',
                }
            }
        },
        legend: {
            show: false,
        },
        yaxis: {
            tickAmount: 7,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            }
        },
        title: {
            text: 'شیرواری',
            style: {
                fontSize: '18px',
            }
        }
    },
};

const milkdrymilk = {
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            },
            title: {
                text: 'DIM',
                style: {
                    fontSize: '18px',
                }
            }
        },
        legend: {
            show: false,
        },
        yaxis: {
            min: 0,
            max: 85,
            tickAmount: 30,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseFloat(val).toFixed(1)
                }
            }
        },
        title: {
            text: 'وزن شیر',
            style: {
                fontSize: '18px',
            }
        }
    },
};

const milkdrybcs = {
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            },
            title: {
                text: 'DIM',
                style: {
                    fontSize: '18px',
                }
            }
        },
        legend: {
            show: false,
        },
        yaxis: {
            min: 0,
            max: 5,
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseFloat(val).toFixed(1)
                }
            }
        },
        title: {
            text: 'نمره BCS',
            style: {
                fontSize: '18px',
            }
        }
    },
};

const milkdrymove = {
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val).toFixed(0)
                }
            },
            title: {
                text: 'DIM',
                style: {
                    fontSize: '18px',
                }
            }
        },
        legend: {
            show: false,
        },
        yaxis: {
            min: 0,
            max: 5,
            categories: [0, 1, 2, 3, 4, 5],
            tickAmount: 5,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val)
                }
            }
        },
        title: {
            text: 'نمره حرکتی',
            style: {
                fontSize: '18px',
            }
        }
    },
};

const pregnant = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        legend: {
            show: false
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val)
                }
            },
            title: {
                text: 'سن (روز)',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            tickAmount: 7,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val)
                }
            },
            title: {
                text: 'تلقیح (روز)',
                style: {
                    fontSize: '18px',
                }
            }
        }
    },
};
const pregnant_time = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        stroke: {
            curve: "straight",
            width: 3,
        },
        chart: {
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#1cbb8c", "#fcb92c", "#4aa3ff"],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
            categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
            },
            title: {
                text: 'مدت آبستنی (ماه)',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
            },
            title: {
                text: 'تعداد دام',
                style: {
                    fontSize: '18px',
                }
            }
        }
    }
};

const nonpregnant = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
            width: 3,
        },
        colors: ["#1cbb8c", "#fcb92c", "#4aa3ff", "#ff3d60", "#343a40", "#fcb92c", "#74788d", "#e83e8c", "#f1734f", "#5438DC", "#564ab1", "#5664d2", "#e83e8c", "#ff3d60", "#f1734f", "#fcb92c", "#1cbb8c", "#050505", "#4aa3ff", "#ffffff", "#74788d", "#343a40", "#5664d2", "#74788d", "#1cbb8c", "#4aa3ff", "#fcb92c", "#ff3d60", "#eff2f7", "#343a40", "#e83e8c"],
        xaxis: {
            categories: ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
            },
            title: {
                text: 'سن (ماه)',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            tickAmount: 7,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val)
                }
            },
            title: {
                text: 'تعداد',
                style: {
                    fontSize: '18px',
                }
            }
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    },
};
const nonpregnant_inoc = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '14px',
            }
        },
        chart: {
            height: 350,
            type: 'scatter',
            zoom: {
                enabled: true,
                type: 'xy'
            },
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        legend: {
            show: false
        },
        colors: ["#1cbb8c", "#fcb92c", "#4aa3ff"],
        xaxis: {
            tickAmount: 10,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val)
                }
            },
            title: {
                text: 'سن (روز)',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            tickAmount: 7,
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return parseInt(val)
                }
            },
            title: {
                text: 'تلقیح (روز)',
                style: {
                    fontSize: '18px',
                }
            }
        }
    },
};

const clafCows = {
    series: [],
    chartOptions: {
        noData: {
            text: "درحال دریافت اطلاعات",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000",
                fontSize: '20px',
            }
        },
        chart: {
            locales: [{
                name: "fa",
                options: {
                    toolbar: {
                        exportToSVG: "SVG دانلود",
                        exportToPNG: "PNG دانلود",
                        exportToCSV: "CSV دانلود",
                    },
                },
            }, ],
            defaultLocale: "fa",
            toolbar: {
                show: true,
                tools: {
                    download: "<i class='ri-download-line'></i>",
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
            },
        },
        plotOptions: {
            bar: {
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#1cbb8c", "#fcb92c", "#4aa3ff", "#ff3d60", "#343a40", "#009dff", "#74788d", "#e83e8c", "#f1734f", "#5438DC", "#564ab1", "#5664d2"],
        grid: {
            borderColor: '#f1f1f1',
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            labels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                },
            },
            title: {
                text: 'سن (ماه)',
                style: {
                    fontSize: '18px',
                }
            }
        },
        yaxis: {
            title: {
                text: 'تعداد دام',
                style: {
                    fontSize: '18px',
                }
            }
        }
    }
};

export {
    livestockAllChart,
    CountCowToday,
    milkdryinoc,
    milkdrybirth,
    milkdrymilk,
    milkdrybcs,
    milkdrymove,
    pregnant,
    pregnant_time,
    nonpregnant,
    nonpregnant_inoc,
    clafCows
};