import Router from '../router'

export const dashboardService = {
    AllList,
    AllSearch,
    AllChart,
    AllChart2,
    Milk,
    MilkPostion,
    Dry,
    DryPostion,
    Pregnant,
    PregnantTime,
    nonPregnant,
    nonPregnantInoc,
    CalfChart,
    Warning,
};


function AllList(Count, From) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "count": Count,
            "from": From
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllSearch(Count, From, Text) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "count": Count,
            "from": From,
            "text": Text
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/all-search`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllChart() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/all-chart`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllChart2() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/all-chart2`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function Milk(Type, MinDIM = 0, MaxDIM = 0) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "type": Type,
            "minDIM": MinDIM,
            "maxDIM": MaxDIM
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/milk`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function MilkPostion(Type, MinDIM = 0, MaxDIM = 0) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "type": Type,
            "minDIM": MinDIM,
            "maxDIM": MaxDIM
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/milkpostion`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function Dry(Type, MinDIM = 0, MaxDIM = 0) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "type": Type,
            "minDIM": MinDIM,
            "maxDIM": MaxDIM
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/dry`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function DryPostion(Type, MinDIM = 0, MaxDIM = 0) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "type": Type,
            "minDIM": MinDIM,
            "maxDIM": MaxDIM
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/drypostion`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function Pregnant() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/pregnant`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function PregnantTime() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/pregnant-time`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function nonPregnant() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/nonpregnant`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function nonPregnantInoc() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/nonpregnantinoc`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function CalfChart() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dash/calfchart`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function Warning(List) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "list": List,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/warning`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('auth');
                location.reload();
            }
            if (response.status === 500 || response.status === 503) {
                Router.push({
                    path: "/error-500",
                    redirect: {
                        name: "Error-500"
                    },
                });
            }
        }
        return data;
    });
}